import SvgIcon from '@mui/material/SvgIcon';

const ThumbsUpIcon = (props) => {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 21 21"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        width: props.width ?? '21',
        height: props.height ?? '21',
        fill: props.fill ?? 'none',
      }}
    >
      <path
        d="M3.27246 8.16895H7.02246V16.2939H3.27246C3.1067 16.2939 2.94773 16.2281 2.83052 16.1109C2.71331 15.9937 2.64746 15.8347 2.64746 15.6689V8.79395C2.64746 8.62819 2.71331 8.46921 2.83052 8.352C2.94773 8.23479 3.1067 8.16895 3.27246 8.16895V8.16895Z"
        stroke="#8289A7"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.02246 8.16895L10.1475 1.91895C10.4758 1.91895 10.8009 1.98361 11.1042 2.10925C11.4075 2.23488 11.6831 2.41903 11.9152 2.65118C12.1474 2.88332 12.3315 3.15892 12.4572 3.46224C12.5828 3.76555 12.6475 4.09064 12.6475 4.41895V6.29395H17.4815C17.6587 6.29395 17.8339 6.33164 17.9955 6.40452C18.1571 6.47741 18.3013 6.58382 18.4186 6.71669C18.5359 6.84957 18.6236 7.00587 18.6759 7.17522C18.7281 7.34458 18.7438 7.52312 18.7218 7.69899L17.7843 15.199C17.7465 15.5013 17.5996 15.7794 17.3712 15.981C17.1428 16.1827 16.8487 16.2939 16.544 16.2939H7.02246"
        stroke="#8289A7"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default ThumbsUpIcon;
