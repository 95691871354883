import { useCallback } from 'react';
import { filteredClaimResults } from 'core/store/claimsV2Slice';
import { useAppSelector } from 'core/store/hooks';
import { useDispatch } from 'react-redux';

const useUpdateUserFeedback = () => {
  const dispatch = useDispatch();
  const filteredClaim = useAppSelector((state) => state.claimsV2.filteredClaim);

  const updateEvidenceWithRelevance = useCallback(
    (sourceId: string, userAgreesRelevance: boolean) => {
      if (!filteredClaim || !filteredClaim.evidence) {
        console.error('No evidence or claim found to update.');
        return;
      }

      const updatedEvidence = filteredClaim.evidence.map((evidence) => {
        if (evidence._id === sourceId) {
          return {
            ...evidence,
            userAgreesRelevance,
          };
        }
        return evidence;
      });

      dispatch(
        filteredClaimResults({
          ...filteredClaim,
          evidence: updatedEvidence,
        })
      );
    },
    [dispatch, filteredClaim]
  );

  const updateEvidenceWithSupport = useCallback(
    (sourceId: string, userAgreesSupport: boolean) => {
      if (!filteredClaim || !filteredClaim.evidence) {
        console.error('No evidence or claim found to update.');
        return;
      }

      const updatedEvidence = filteredClaim.evidence.map((evidence) => {
        if (evidence._id === sourceId) {
          return {
            ...evidence,
            userAgreesSupport,
          };
        }
        return evidence;
      });

      dispatch(
        filteredClaimResults({
          ...filteredClaim,
          evidence: updatedEvidence,
        })
      );
    },
    [dispatch, filteredClaim]
  );

  const updateEvidenceWithUserFeedback = useCallback(
    (
      sourceId: string,
      userAgreesRelevance?: boolean,
      userAgreesSupport?: boolean
    ) => {
      if (!filteredClaim || !filteredClaim.evidence) {
        console.error('No evidence or claim found to update.');
        return;
      }

      const updatedEvidence = filteredClaim.evidence.map((evidence) => {
        if (evidence._id === sourceId) {
          return {
            ...evidence,
            ...(userAgreesRelevance !== undefined && {
              userAgreesRelevance,
            }),
            ...(userAgreesSupport !== undefined && {
              userAgreesSupport,
            }),
          };
        }
        return evidence;
      });

      dispatch(
        filteredClaimResults({
          ...filteredClaim,
          evidence: updatedEvidence,
        })
      );
    },
    [dispatch, filteredClaim]
  );

  return {
    updateEvidenceWithRelevance,
    updateEvidenceWithSupport,
    updateEvidenceWithUserFeedback,
  };
};

export default useUpdateUserFeedback;
