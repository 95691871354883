import { franc } from 'franc';

// ISO 639-3 to ISO 639-1 mapping
export const iso6393To6391: { [key: string]: string } = {
  aar: 'aa',
  abk: 'ab',
  ave: 'ae',
  afr: 'af',
  aka: 'ak',
  amh: 'am',
  arg: 'an',
  ara: 'ar',
  asm: 'as',
  ava: 'av',
  aym: 'ay',
  aze: 'az',
  bak: 'ba',
  bel: 'be',
  bul: 'bg',
  bih: 'bh',
  bis: 'bi',
  bam: 'bm',
  ben: 'bn',
  bod: 'bo',
  bre: 'br',
  bos: 'bs',
  cat: 'ca',
  che: 'ce',
  cha: 'ch',
  cos: 'co',
  cre: 'cr',
  ces: 'cs',
  chu: 'cu',
  chv: 'cv',
  cym: 'cy',
  dan: 'da',
  deu: 'de',
  div: 'dv',
  dzo: 'dz',
  ewe: 'ee',
  ell: 'el',
  eng: 'en',
  epo: 'eo',
  spa: 'es',
  est: 'et',
  eus: 'eu',
  fas: 'fa',
  ful: 'ff',
  fin: 'fi',
  fij: 'fj',
  fao: 'fo',
  fra: 'fr',
  fry: 'fy',
  gle: 'ga',
  gla: 'gd',
  glg: 'gl',
  grn: 'gn',
  guj: 'gu',
  glv: 'gv',
  hau: 'ha',
  heb: 'he',
  hin: 'hi',
  hmo: 'ho',
  hrv: 'hr',
  hat: 'ht',
  hun: 'hu',
  hye: 'hy',
  her: 'hz',
  ina: 'ia',
  ind: 'id',
  ile: 'ie',
  ibo: 'ig',
  iii: 'ii',
  ipk: 'ik',
  ido: 'io',
  isl: 'is',
  ita: 'it',
  iku: 'iu',
  jpn: 'ja',
  jav: 'jv',
  kat: 'ka',
  kon: 'kg',
  kik: 'ki',
  kua: 'kj',
  kaz: 'kk',
  kal: 'kl',
  khm: 'km',
  kan: 'kn',
  kor: 'ko',
  kau: 'kr',
  kas: 'ks',
  kur: 'ku',
  kom: 'kv',
  cor: 'kw',
  kir: 'ky',
  lat: 'la',
  ltz: 'lb',
  lug: 'lg',
  lim: 'li',
  lin: 'ln',
  lao: 'lo',
  lit: 'lt',
  lub: 'lu',
  lav: 'lv',
  mlg: 'mg',
  mah: 'mh',
  mri: 'mi',
  mkd: 'mk',
  mal: 'ml',
  mon: 'mn',
  mar: 'mr',
  msa: 'ms',
  mlt: 'mt',
  mya: 'my',
  nau: 'na',
  nob: 'nb',
  nde: 'nd',
  nep: 'ne',
  ndo: 'ng',
  nld: 'nl',
  nno: 'nn',
  nor: 'no',
  nbl: 'nr',
  nav: 'nv',
  nya: 'ny',
  oci: 'oc',
  oji: 'oj',
  orm: 'om',
  ori: 'or',
  oss: 'os',
  pan: 'pa',
  pli: 'pi',
  pol: 'pl',
  pus: 'ps',
  por: 'pt',
  que: 'qu',
  roh: 'rm',
  run: 'rn',
  ron: 'ro',
  rus: 'ru',
  kin: 'rw',
  san: 'sa',
  srd: 'sc',
  snd: 'sd',
  sme: 'se',
  sag: 'sg',
  sin: 'si',
  slk: 'sk',
  slv: 'sl',
  smo: 'sm',
  sna: 'sn',
  som: 'so',
  sqi: 'sq',
  srp: 'sr',
  ssw: 'ss',
  sot: 'st',
  sun: 'su',
  swe: 'sv',
  swa: 'sw',
  tam: 'ta',
  tel: 'te',
  tgk: 'tg',
  tha: 'th',
  tir: 'ti',
  tuk: 'tk',
  tgl: 'tl',
  tsn: 'tn',
  ton: 'to',
  tur: 'tr',
  tso: 'ts',
  tat: 'tt',
  twi: 'tw',
  tah: 'ty',
  uig: 'ug',
  ukr: 'uk',
  urd: 'ur',
  uzb: 'uz',
  ven: 've',
  vie: 'vi',
  vol: 'vo',
  wln: 'wa',
  wol: 'wo',
  xho: 'xh',
  yid: 'yi',
  yor: 'yo',
  zha: 'za',
  zho: 'zh',
  zul: 'zu',
  fil: 'fil',
};

// ISO 639-1 to full locale code mapping
export const languageToLocale: { [key: string]: string } = {
  aa: 'aa',
  ab: 'ab',
  ae: 'ae',
  af: 'af-ZA',
  ak: 'ak',
  am: 'am-ET',
  an: 'an',
  ar: 'ar-SA',
  as: 'as-IN',
  av: 'av',
  ay: 'ay',
  az: 'az-AZ',
  ba: 'ba',
  be: 'be-BY',
  bg: 'bg-BG',
  bh: 'bh',
  bi: 'bi',
  bm: 'bm',
  bn: 'bn-IN',
  bo: 'bo',
  br: 'br',
  bs: 'bs-BA',
  ca: 'ca-ES',
  ce: 'ce',
  ch: 'ch',
  co: 'co',
  cr: 'cr',
  cs: 'cs-CZ',
  cu: 'cu',
  cv: 'cv',
  cy: 'cy-GB',
  da: 'da-DK',
  de: 'de-DE',
  dv: 'dv',
  dz: 'dz',
  ee: 'ee',
  el: 'el-GR',
  en: 'en-US',
  eo: 'eo',
  es: 'es-ES',
  et: 'et-EE',
  eu: 'eu-ES',
  fa: 'fa-IR',
  ff: 'ff',
  fi: 'fi-FI',
  fj: 'fj',
  fo: 'fo-FO',
  fr: 'fr-FR',
  fy: 'fy-NL',
  ga: 'ga-IE',
  gd: 'gd-GB',
  gl: 'gl-ES',
  gn: 'gn',
  gu: 'gu-IN',
  gv: 'gv',
  ha: 'ha',
  he: 'he-IL',
  hi: 'hi-IN',
  ho: 'ho',
  hr: 'hr-HR',
  ht: 'ht',
  hu: 'hu-HU',
  hy: 'hy-AM',
  hz: 'hz',
  ia: 'ia',
  id: 'id-ID',
  ie: 'ie',
  ig: 'ig',
  ii: 'ii',
  ik: 'ik',
  io: 'io',
  is: 'is-IS',
  it: 'it-IT',
  iu: 'iu',
  ja: 'ja-JP',
  jv: 'jv',
  ka: 'ka-GE',
  kg: 'kg',
  ki: 'ki',
  kj: 'kj',
  kk: 'kk-KZ',
  kl: 'kl',
  km: 'km-KH',
  kn: 'kn-IN',
  ko: 'ko-KR',
  kr: 'kr',
  ks: 'ks',
  ku: 'ku',
  kv: 'kv',
  kw: 'kw',
  ky: 'ky-KG',
  la: 'la',
  lb: 'lb-LU',
  lg: 'lg',
  li: 'li',
  ln: 'ln',
  lo: 'lo-LA',
  lt: 'lt-LT',
  lu: 'lu',
  lv: 'lv-LV',
  mg: 'mg',
  mh: 'mh',
  mi: 'mi-NZ',
  mk: 'mk-MK',
  ml: 'ml-IN',
  mn: 'mn-MN',
  mr: 'mr-IN',
  ms: 'ms-MY',
  mt: 'mt-MT',
  my: 'my-MM',
  na: 'na',
  nb: 'nb-NO',
  nd: 'nd',
  ne: 'ne-NP',
  ng: 'ng',
  nl: 'nl-NL',
  nn: 'nn-NO',
  no: 'no-NO',
  nr: 'nr',
  nv: 'nv',
  ny: 'ny',
  oc: 'oc',
  oj: 'oj',
  om: 'om',
  or: 'or-IN',
  os: 'os',
  pa: 'pa-IN',
  pi: 'pi',
  pl: 'pl-PL',
  ps: 'ps',
  pt: 'pt-PT',
  qu: 'qu',
  rm: 'rm-CH',
  rn: 'rn',
  ro: 'ro-RO',
  ru: 'ru-RU',
  rw: 'rw',
  sa: 'sa',
  sc: 'sc',
  sd: 'sd',
  se: 'se-NO',
  sg: 'sg',
  si: 'si-LK',
  sk: 'sk-SK',
  sl: 'sl-SI',
  sm: 'sm',
  sn: 'sn',
  so: 'so-SO',
  sq: 'sq-AL',
  sr: 'sr-RS',
  ss: 'ss',
  st: 'st',
  su: 'su',
  sv: 'sv-SE',
  sw: 'sw-KE',
  ta: 'ta-IN',
  te: 'te-IN',
  tg: 'tg',
  th: 'th-TH',
  ti: 'ti',
  tk: 'tk-TM',
  tl: 'tl-PH',
  tn: 'tn',
  to: 'to',
  tr: 'tr-TR',
  ts: 'ts',
  tt: 'tt',
  tw: 'tw',
  ty: 'ty',
  ug: 'ug',
  uk: 'uk-UA',
  ur: 'ur-PK',
  uz: 'uz-UZ',
  ve: 've',
  vi: 'vi-VN',
  vo: 'vo',
  wa: 'wa',
  wo: 'wo',
  xh: 'xh-ZA',
  yi: 'yi',
  yo: 'yo',
  za: 'za',
  zh: 'zh-CN',
  zu: 'zu-ZA',
  fil: 'fil-PH',
  iw: 'he-IL',
};

// Helper functions
export const detectLanguage = (text: string): string => {
  const detectedLang = franc(text);
  return iso6393To6391[detectedLang] || 'en';
};

export const getLocaleCode = (lang: string): string => {
  return languageToLocale[lang] || 'en-US';
};
