import React, { useState, MouseEvent } from 'react';
import {
  Stack,
  IconButton,
  MenuItem,
  Divider,
  Menu as MenuComponent,
} from '@mui/material';
import MenuHeader from './MenuHeader';
import { CLOUD_MIST } from 'utils/theme';
const screenWidth = window.innerWidth;

const calculateRightValue = (screenWidth) => {
  const maxWidth = 480;
  const minWidth = 370;
  const maxRight = 295;
  const minRight = 180;

  if (screenWidth >= maxWidth) return maxRight;
  if (screenWidth <= minWidth) return minRight;

  const right = screenWidth - 200;

  return right;
};

const MenuStyles = {
  elevation: 0,
  sx: {
    overflow: 'visible',
    borderRadius: '8px',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.22))',
    mt: 2,
    width: '320px',
    '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      right: screenWidth < 480 ? calculateRightValue(screenWidth) : 295,
      width: 10,
      height: 10,
      bgcolor: 'background.paper',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0,
    },
  },
};

const MenuItemStyles = {
  color: '#545454',
  '&:hover': {
    backgroundColor: 'transparent',
    color: '#212427',
  },
  padding: { mobile: 1, tablet: 2, desktop: 2 },
};

export interface ActionMenuListItem {
  name: string;
  icons: {
    icon: JSX.Element;
    onClick: () => void;
  }[];
}

interface MenuProps {
  icon: JSX.Element;
  title: string;
  items: ActionMenuListItem[];
  source: Source;
}

const ThumbsDownFeedbackActionMenuList = ({
  icon,
  title,
  items,
  source,
}: MenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedIcons, setSelectedIcons] = useState<{
    [key: string]: boolean;
  }>({});
  const [clickedItems, setClickedItems] = useState<Set<number>>(new Set());

  const handleMenuOpen = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setClickedItems(new Set());
  };

  const handleIconClick = (
    menuIndex: number,
    iconIndex: number,
    onClick: () => void
  ) => {
    const key = `${menuIndex}-${iconIndex}`;

    setSelectedIcons((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));

    onClick();

    setClickedItems((prevState) => {
      const updatedSet = new Set(prevState);
      updatedSet.add(menuIndex);
      return updatedSet;
    });

    if (clickedItems.size + 1 === items.length) {
      handleMenuClose();
    }
  };

  const triggerIconFill =
    source.userAgreesRelevance === false || source.userAgreesSupport === false
      ? 'grey'
      : 'none';

  return (
    <Stack>
      <IconButton
        aria-label={title}
        onClick={handleMenuOpen}
        sx={{
          '&:hover': { background: CLOUD_MIST },
        }}
      >
        {React.cloneElement(icon, { fill: triggerIconFill })}
      </IconButton>

      <MenuComponent
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        slotProps={{ paper: MenuStyles }}
        sx={{ '& .MuiMenu-list': { paddingTop: 0, paddingBottom: 0 } }}
        onClose={handleMenuClose}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      >
        <MenuHeader title={title} onClick={handleMenuClose} />
        <Divider />

        {items.map((item, menuIndex) => (
          <MenuItem key={menuIndex} sx={MenuItemStyles}>
            <Stack direction="row" alignItems="center" sx={{ width: '100%' }}>
              <Stack direction="row" gap={1}>
                {item.icons.map(({ icon, onClick }, iconIndex) => {
                  const key = `${menuIndex}-${iconIndex}`;
                  const isSelected = selectedIcons[key];

                  return (
                    <IconButton
                      key={iconIndex}
                      onClick={() =>
                        handleIconClick(menuIndex, iconIndex, onClick)
                      }
                      sx={{
                        border: '1px solid #6C727A30',
                        width: '30px',
                        height: '30px',
                      }}
                    >
                      {React.cloneElement(icon, {
                        fill: isSelected ? 'grey' : 'none',
                      })}
                    </IconButton>
                  );
                })}
              </Stack>

              <span style={{ paddingLeft: '16px' }}>{item.name}</span>
            </Stack>
          </MenuItem>
        ))}
      </MenuComponent>
    </Stack>
  );
};

export default ThumbsDownFeedbackActionMenuList;
