import { Box, Button, Stack, Typography } from '@mui/material';
import useFilterActions from 'pages/PlaygroundV2Page/Filter/useFilterActions';
import {
  getMissingSources,
  isFilterAdjustedBySearchEngines,
} from 'pages/PlaygroundV2Page/helpers';
import { BORDER_COLOR, BRIGHT_BLUE, DARK_GREY } from 'utils/theme';

interface FilterHelperProps {
  claim: Claim;
  selectedSearchEngines: string[];
}

const FilterHelper = ({ claim, selectedSearchEngines }: FilterHelperProps) => {
  const { resetFilters } = useFilterActions();

  const isFilterAdjusted = isFilterAdjustedBySearchEngines(
    selectedSearchEngines
  );

  const missingSources = getMissingSources({ claim, selectedSearchEngines });

  return (
    <Box>
      {isFilterAdjusted && (
        <Stack
          borderTop="1px solid"
          borderColor={BORDER_COLOR}
          py={2}
          direction="row"
          alignItems="center"
        >
          <Button
            variant="contained"
            onClick={() => resetFilters()}
            sx={{
              bgcolor: BRIGHT_BLUE,
              borderRadius: '24px',
              boxShadow: 'none',
              textTransform: 'none',
            }}
          >
            Clear all filters
          </Button>
          {missingSources && (
            <Typography variant="subtitle2" color={DARK_GREY} ml={2}>
              {missingSources.length} source{missingSources.length > 1 && 's'}{' '}
              {missingSources.length > 1 ? 'are' : 'is'} hidden
            </Typography>
          )}
        </Stack>
      )}
    </Box>
  );
};

export default FilterHelper;
