import { IconButton, Stack } from '@mui/material';
import CloseIcon from 'assets/img/icons/CloseIcon';

interface MenuHeaderProps {
  title: string;
  onClick: () => void;
}

const MenuHeader = ({ onClick }: MenuHeaderProps) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      px={2.25}
      py={1.5}
      justifyContent="flex-end"
    >
      <IconButton sx={{ width: '25px', height: '25px' }} onClick={onClick}>
        <CloseIcon sx={{ width: '15px', height: '15px' }} />
      </IconButton>
    </Stack>
  );
};

export default MenuHeader;
