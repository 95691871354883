import {
  Box,
  Divider,
  Link,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React from 'react';
import Favicon from 'components/Favicon';
import SeeMoreButton from 'components/SeeMoreButton';
import moment from 'moment';
import { BORDER_COLOR, DARKER_GREY, LIGHT_BLACK } from 'utils/theme';
import { useEvidenceLabel } from 'pages/PlaygroundV2Page/hooks/useEvidendeLabel';
import SourceLabel from 'pages/PlaygroundV2Page/NoSourcesFoundComponent/components/SourceLabel';
import ReadAloudButton from './ReadAloudButton';
import CopyToClipboardButton from '../../../Summary/components/CopyToClipboardButton';
import FeedbackAction from '../FeedbackAction';

export const ClaimEvidenceHeader = ({
  index,
  evidence,
}: {
  index: number;
  evidence: Source;
}) => {
  const { getLabel } = useEvidenceLabel();

  const label = getLabel(evidence);
  const theme = useTheme();

  const isMobileScreen = useMediaQuery(theme.breakpoints.down('tablet'));
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      mt={3}
      spacing={1}
    >
      <Typography
        fontSize="18px"
        fontWeight="500"
        my={2}
        display="flex"
        flexDirection="row"
      >
        <Box
          component="span"
          fontWeight="400"
          fontSize="16px"
          paddingRight="4px"
          paddingTop="2px"
        >
          {index + 1}.
        </Box>{' '}
        <Box component="span">{evidence.title}</Box>
      </Typography>
      {!isMobileScreen && (
        <Box
          sx={{
            padding: '12px',
          }}
        >
          <SourceLabel label={label} />
        </Box>
      )}
    </Stack>
  );
};

export const ClaimEvidenceBody = ({
  evidence,
  claim,
}: {
  evidence: Source;
  claim: Claim;
}) => {
  const textToRead = `${evidence.title ?? ''}. ${
    evidence.evidenceSnippet ?? ''
  }`;
  const textToCopy = evidence.url ?? '';

  return (
    <Box
      sx={{
        background: 'rgba(241, 242, 251, 0.6)',
        borderRadius: '8px',
        marginLeft: {
          mobile: 0,
          tablet: 0,
          desktop: '20px !important',
        },

        marginRight: {
          mobile: 0,
          tablet: 0,
          desktop: '20px !important',
        },
        padding: {
          mobile: '12px',
          tablet: '16px',
          desktop: '24px 117px 24px 40px',
        },
      }}
    >
      <SeeMoreButton
        name={evidence.title ?? ''}
        text={evidence.evidenceSnippet ?? ''}
      />
      <Box display="flex" flexDirection="row">
        <ReadAloudButton text={textToRead} />
        <CopyToClipboardButton textToCopy={textToCopy} />
        <FeedbackAction claim={claim} source={evidence} />
      </Box>
    </Box>
  );
};

export const ClaimEvidenceFooter = ({ evidence }: { evidence: Source }) => {
  const { getLabel } = useEvidenceLabel();

  const label = getLabel(evidence);
  const theme = useTheme();

  const isMobileScreen = useMediaQuery(theme.breakpoints.down('tablet'));

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: {
            mobile: 'wrap',
            tablet: 'no-wrap',
            desktop: 'no-wrap',
          },
          gap: '10px',
        }}
      >
        {evidence.domain && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              borderRight: `1px solid ${BORDER_COLOR}`,
              paddingRight: '10px',
            }}
          >
            <Favicon rootDomain={evidence.domain} width={24} height={24} />
            <Link
              sx={{
                fontSize: '12px',
                ml: '8px',
              }}
              href={evidence.url}
              target="_blank"
            >
              {evidence.domainName ?? evidence.domain}
            </Link>
          </Box>
        )}

        {evidence.publishDate && (
          <Box
            className="item"
            sx={{
              borderRight: `1px solid ${BORDER_COLOR}`,
              paddingRight: '10px',
            }}
          >
            <Typography fontSize="12px" color={DARKER_GREY}>
              {moment(evidence.publishDate).year()}
            </Typography>
          </Box>
        )}

        {(evidence.citationCount ?? 0) > 0 && (
          <Box
            className="item"
            sx={{
              borderRight: `1px solid ${BORDER_COLOR}`,
              paddingRight: '10px',
            }}
          >
            <Typography fontSize="12px" color={DARKER_GREY}>
              <span
                style={{
                  textDecoration: 'underline',
                  color: LIGHT_BLACK,
                }}
              >
                {evidence.citationCount}
              </span>{' '}
              citations
            </Typography>
          </Box>
        )}

        {evidence.authors?.length ? (
          <Box
            className="item"
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
              borderRight: `1px solid ${BORDER_COLOR}`,
              paddingRight: '10px',
            }}
          >
            <Typography fontSize="12px" color={DARKER_GREY}>
              <span>{evidence.authors[0]}</span>{' '}
              {evidence.authors.length > 1 && (
                <>
                  +{' '}
                  <span
                    style={{
                      textDecoration: 'underline',
                      color: LIGHT_BLACK,
                    }}
                  >
                    {evidence.authors.length - 1} authors
                  </span>
                </>
              )}
            </Typography>
          </Box>
        ) : null}

        <Box
          sx={{
            paddingRight: '10px',
          }}
        >
          <Typography fontSize="12px">
            {evidence.searchEngine === 'ElasticSearch'
              ? 'FactiSearch'
              : evidence.searchEngine}
          </Typography>
        </Box>
      </Box>

      {isMobileScreen && (
        <>
          <Divider
            flexItem
            sx={{
              width: '100%',
              mt: '20px',
              mb: '16px',
              borderColor: BORDER_COLOR,
            }}
          />
          <Box sx={{ mt: '10px' }}>
            <SourceLabel label={label} />
          </Box>
        </>
      )}
    </Box>
  );
};
