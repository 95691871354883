import SvgIcon from '@mui/material/SvgIcon';

const ThumbsDownIcon = (props) => {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 19 19"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        width: props.width ?? '19',
        height: props.height ?? '19',
        fill: props.fill ?? 'none',
      }}
    >
      <path
        d="M3.02246 3.41895H6.39746V10.7314H3.02246C2.87328 10.7314 2.7302 10.6722 2.62471 10.5667C2.51922 10.4612 2.45996 10.3181 2.45996 10.1689V3.98145C2.45996 3.83226 2.51922 3.68919 2.62471 3.5837C2.7302 3.47821 2.87328 3.41895 3.02246 3.41895V3.41895Z"
        stroke="#8289A7"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.39746 10.7314L9.20996 16.3564C9.50544 16.3564 9.79802 16.2982 10.071 16.1852C10.344 16.0721 10.592 15.9064 10.801 15.6974C11.0099 15.4885 11.1756 15.2405 11.2887 14.9675C11.4018 14.6945 11.46 14.4019 11.46 14.1064V12.4189H15.8106C15.9701 12.4189 16.1278 12.385 16.2732 12.3194C16.4186 12.2538 16.5484 12.1581 16.654 12.0385C16.7595 11.9189 16.8385 11.7782 16.8855 11.6258C16.9326 11.4734 16.9467 11.3127 16.9269 11.1544L16.0831 4.40441C16.0491 4.13232 15.9169 3.88203 15.7114 3.70056C15.5058 3.51909 15.241 3.41895 14.9668 3.41895H6.39746"
        stroke="#8289A7"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default ThumbsDownIcon;
