import { Box, Button, Popover, useMediaQuery, useTheme } from '@mui/material';
import FilterIcon from 'assets/img/icons/FilterIcon';
import { setOpenFilterPopover } from 'core/store/claimsV2Slice';
import { useAppSelector } from 'core/store/hooks';
import useDateFilter from 'hooks/useDateFilter';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { DARKER_GREY } from 'utils/theme';

const PopoverComponent = ({ children }: { children: JSX.Element[] }) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const isMobileScreen = useMediaQuery(theme.breakpoints.down('tablet'));

  const selectedSourceDate = useAppSelector(
    (state) => state.claimsV2.selectedSourceDate
  );
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const openFilterPopover = useAppSelector(
    (state) => state.claimsV2.openFilterPopover
  );

  const { updateClaimByDate } = useDateFilter();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    dispatch(setOpenFilterPopover(true));
  };

  const handleClose = () => {
    setAnchorEl(null);
    dispatch(setOpenFilterPopover(false));
  };

  useEffect(() => {
    if (selectedSourceDate) {
      updateClaimByDate(selectedSourceDate);
    }
    // filters claims sources by date just when the app loads
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <Button
        aria-label="filter"
        onClick={handleClick}
        variant="contained"
        disableElevation
        endIcon={!isMobileScreen && <FilterIcon />}
        sx={{
          borderRadius: { mobile: '50%', tablet: '20px', desktop: '20px' },
          width: { mobile: '35px', tablet: 'auto', desktop: 'auto' },
          height: { mobile: '35px', tablet: 'auto', desktop: 'auto' },
          minWidth: '35px',
          color: DARKER_GREY,
          background: '#F1F2FB',
          textTransform: 'capitalize',
          '&:hover': {
            background: '#F1F2FB',
          },
        }}
      >
        {isMobileScreen ? <FilterIcon /> : 'Filter'}
      </Button>
      {anchorEl && (
        <Popover
          id="simple-popover"
          open={openFilterPopover}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
          slotProps={{
            paper: {
              style: {
                boxShadow: '1px 0px 15.9px -3px rgba(128,155,181,0.3)',
                borderRadius: '12px',
                border: '1px solid #E8EAEB',
              },
            },
          }}
        >
          {children}
        </Popover>
      )}
    </Box>
  );
};

export default PopoverComponent;
