import { Box, Divider, Stack, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import Card from '../components/Card';
import ActionBox from './components/ActionBox';
import DonutChart, { DonutChartMobileView } from './components/DonutChart';
import SummaryFooter from './components/SummaryFooter';
import SummaryHeader from './components/SummaryHeader';
import SummaryText from './components/SummaryText';

interface SummaryProps {
  isLoading: boolean;
  claim: Claim;
}

const Summary: React.FC<SummaryProps> = ({ isLoading, claim }) => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('tablet'));

  return (
    <>
      {isMobileScreen && (
        <DonutChartMobileView
          isLoading={isLoading}
          evidence={claim.evidence ?? []}
        />
      )}
      <Card
        sx={{
          paddingX: { mobile: '16px', tablet: '30px', desktop: '30px' },
          paddingY: { mobile: '16px', tablet: '25px', desktop: '25px' },
        }}
      >
        <Stack divider={<Divider />}>
          <Stack
            direction="row"
            pb={3}
            divider={<Divider orientation="vertical" flexItem />}
            sx={{
              gap: { mobile: 2, tablet: 2, desktop: 6 },
            }}
          >
            <Box flex={1.25}>
              <Stack>
                <SummaryHeader />
                <SummaryText
                  isLoading={isLoading}
                  summary={claim.summary ?? []}
                />
                <SummaryFooter isLoading={isLoading} claim={claim} />
              </Stack>
            </Box>
            {!isMobileScreen && (
              <Box flex={1} alignSelf="center">
                <DonutChart
                  isLoading={isLoading}
                  evidence={claim.evidence ?? []}
                />
              </Box>
            )}
          </Stack>
          {!isLoading && (
            <Box p={2} pb={0}>
              <ActionBox />
            </Box>
          )}
        </Stack>
      </Card>
    </>
  );
};

export default Summary;
