import { Box, Stack } from '@mui/material';
import { useAppSelector } from 'core/store/hooks';
import { useMemo } from 'react';
import useSourceTypes from '../hooks/useSourceTypes';
import ClaimEvidence from './components/ClaimEvidence';
import ClaimSummary from './components/ClaimSummary';

const Claim = () => {
  const filteredClaim = useAppSelector((state) => state.claimsV2.filteredClaim);
  const claim = useAppSelector((state) => state.claimsV2.claim);
  const selectedSortingAccordion = useAppSelector(
    (state) => state.claimsV2.selectedSortingAccordion
  );
  const selectedSearchEngines = useAppSelector(
    (state) => state.claimsV2.selectedSearchEngines
  );

  const { groupedSourceTypes } = useSourceTypes(filteredClaim.evidence ?? []);

  const sortedOrFilteredSources = useMemo(
    () => groupedSourceTypes[selectedSortingAccordion],
    [groupedSourceTypes, selectedSortingAccordion]
  );

  return (
    <Box>
      {filteredClaim && (
        <ClaimSummary
          filteredClaim={filteredClaim}
          claim={claim}
          selectedSearchEngines={selectedSearchEngines}
        />
      )}
      <Stack spacing={2} pb={2}>
        {sortedOrFilteredSources?.map((source, index) => (
          <ClaimEvidence
            index={index}
            evidence={source}
            key={index}
            claim={filteredClaim}
          />
        ))}
      </Stack>
    </Box>
  );
};

export default Claim;
