import { Box, Stack, Typography } from '@mui/material';
import Filter from 'pages/PlaygroundV2Page/Filter';
import FilterHelper from './FilterHelper';
import SortingAccordion from './SortingAccordion';

interface ClaimSummaryProps {
  claim: Claim;
  filteredClaim: Claim;
  selectedSearchEngines: string[];
}

const ClaimSummary = ({
  claim,
  filteredClaim,
  selectedSearchEngines,
}: ClaimSummaryProps) => (
  <Box
    sx={{
      background: 'white',
      border: '1px solid #E8EAEB',
      px: { mobile: '16px', tablet: '30px', desktop: '30px' },
      paddingTop: { mobile: 2, tablet: 2, desktop: 3 },
      borderTopLeftRadius: '12px',
      borderTopRightRadius: '12px',
      mb: 2,
      mt: { mobile: '20px', tablet: '24px', desktop: '24px' },
      position: 'sticky',
      top: 0,
      backgroundColor: 'white',
      zIndex: 1,
    }}
  >
    <Stack
      direction="column"
      justifyContent="space-between"
      alignItems="flex-start"
      gap={2}
    >
      <Stack
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        sx={{ width: '100%' }}
      >
        <Typography fontSize="20px">{filteredClaim.claim}</Typography>

        <Filter />
      </Stack>
      {filteredClaim.evidence && (
        <SortingAccordion evidence={filteredClaim.evidence} />
      )}
    </Stack>
    <FilterHelper claim={claim} selectedSearchEngines={selectedSearchEngines} />
  </Box>
);

export default ClaimSummary;
